import axios from 'axios'
import store from '@/store'
import { Message } from 'element-ui'
import router from '@/router'
// 基本路径
const baseURL = '/api'

let i = 0
const request = axios.create({
  baseURL,
  timeout: 60000
})
request.interceptors.request.use(
  (config) => {
    // config.headers.Authorization = 'Bearer' + '' + store.state.token // 设置响应头
    // config.headers['LOGIN-TYPE'] = 'PWD'
    // config.headers.Connection = 'keep-alive'

    return config
  },
  (err) => {
    return err
  }
)
request.interceptors.response.use(
  (config) => {
    return config
  },
  (err) => {
    if (err.response.status === 424) {
      // if (store.state.token1 !== '') {
      //   store.commit('SET_TOKEN', store.state.Token1)
      //   console.log(store.state.Token1)
      //   store.commit('SET_TOKEN1', '')
      // } else {
      store.commit('SET_TOKEN', '')
      store.commit('SET_TOKEN1', '')
      store.commit('SET_USER_INFO', '')
      store.commit('SET_PHONE', '')
      store.commit('SET_USER_ID', '')
      i++
      if (i === 1) {
        Message.error('登录状态过期，请重新登录')
      }
      router.replace('login')
      // setTimeout(() => {
      //   location.reload()
      // }, 300)
      // }
    }
    return err.response
  }
)

export default request
