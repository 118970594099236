import request from '@/utils/request'
import store from '@/store'
// 获取我的信息
export function getUserInfo () {
  return request({
    url: '/app/appuser/listUser',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${store.state.token}`,
      'CLIENT-TOC': 'Y',
      'LOGIN-TYPE': 'PWD'
    }

  })
}
// 获取地区列表
export function getAreaList (id = 0) {
  return request({
    url: '/app/appAddress/listArea',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${store.state.token}`,
      'CLIENT-TOC': 'Y',
      'LOGIN-TYPE': 'PWD'
    },
    params: {
      pid: id
    }
  })
}
export function getDeAddress (id) {
  console.log(id)
  return request({
    url: '/app/appAddress/getAddress',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${store.state.token}`,
      'CLIENT-TOC': 'Y',
      'LOGIN-TYPE': 'PWD'
    },
    params: {
      adressId: id
    }
  })
}
// 通过id获取城市
export function getCity (id) {
  return request({
    url: '/app/appAddress/getById',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${store.state.token}`,
      'CLIENT-TOC': 'Y',
      'LOGIN-TYPE': 'PWD'
    },
    params: {
      id: id
    }
  })
}
// 删除地址
export function delAddress (id) {
  return request({
    url: '/app/appAddress/del',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${store.state.token}`,
      'CLIENT-TOC': 'Y',
      'LOGIN-TYPE': 'PWD'
    },
    params: {
      id: id
    }
  })
}

// 获取收货地址
export function getAddress () {
  return request({
    url: '/app/appAddress/listPageAddress',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${store.state.token}`,
      'CLIENT-TOC': 'Y',
      'LOGIN-TYPE': 'PWD'
    },
    params: {
      addressFlag: 2
    }

  })
}
// 添加地址
export function addAddress (data) {
  return request({
    url: '/app/appAddress/updateOrSave',
    method: 'post',
    headers: {
      Authorization: `Bearer ${store.state.token}`,
      'CLIENT-TOC': 'Y',
      'LOGIN-TYPE': 'PWD'
    },
    data: data

  })
}
// 修改个人信息
export function changeUserInfo (data) {
  return request({
    url: '/app/appuser/edit',
    method: 'put',
    headers: {
      Authorization: `Bearer ${store.state.token}`,
      'CLIENT-TOC': 'Y',
      'LOGIN-TYPE': 'PWD'
    },
    data: data

  })
}
// 忘记密码
export function changeUserInfo1 (data) {
  return request({
    url: '/app/appuser/forget',
    method: 'put',
    headers: {
      Authorization: `Bearer ${store.state.token}`,
      'CLIENT-TOC': 'Y',
      'LOGIN-TYPE': 'PWD'
    },
    data: data

  })
}
export function userReg1 (data) {
  return request({
    url: '/app/appuser/register',
    method: 'post',
    headers: {
      Authorization: `Bearer ${store.state.token}`,
      'CLIENT-TOC': 'Y',
      'LOGIN-TYPE': 'PWD'
    },
    data: data

  })
}
