import request from '@/utils/request'
// 获取手机验证码
export function getPhoneCode (phone) {
  return request({
    url: `app/appmobile/${phone}`
  })
}
// 用户注册
export function userReg (data) {
  console.log(data)
  return request({
    url: '/app/appuser/register',
    method: 'post',
    data: data,
    headers: {
      'LOGIN-TYPE': 'PWD'
    }
  })
}
// 用户登录
export function userLogin (data) {
  console.log(data)
  return request({
    url: '/auth/oauth2/token',
    method: 'post',
    headers: {
      Authorization: 'Basic YXBwOmFwcA==',
      'LOGIN-TYPE': 'PWD',
      Connection: 'keep-alive',
      'CLIENT-TOC': 'Y'

    },
    params: data
  })
}
// 获取token
export function getToken (data) {
  console.log(data)
  return request({
    url: '/auth/oauth2/token',
    method: 'post',
    params: data,
    headers: {
      Authorization: 'Basic YXBwOmFwcA==',
      'CLIENT-TOC': 'Y'
    }
  })
}
