import { getToken, userLogin } from '@/api/login'
import router from '@/router'
import { Message } from 'element-ui'
import { getUserInfo } from '@/api/my'
import { getOrderList } from '@/api/order'
const actions = {
  code_set_token ({ commit }, data) {
    const info = {
      mobile: 'APP-SMS@' + data.phone,
      code: data.mobileCode,
      grant_type: 'mobile',
      scope: 'server'
    }
    getToken(info).then(res => {
      if (res.data.msg === '验证码不合法') {
        commit('SET_CODE_FLAG', true)
        let timer = null
        commit('SET_CODE_FLAG', true)
        clearTimeout(timer)
        timer = setTimeout(() => {
          commit('SET_CODE_FLAG', false)
        }, 2000)
      } else if (res.status === 200) {
        Message.success('登录成功')
        commit('SET_TOKEN', res.data.access_token)
        commit('SET_TOKEN1', res.data.refresh_token)
        commit('SET_USER_INFO', res.data)
        commit('SET_CODE_FLAG', false)
        commit('SET_PHONE', data.phone)
        commit('SET_USER_ID', res.data.user_id)
        if (router.currentRoute.name !== 'index') {
          router.replace('my')
        } else {
          getUserInfo().then((res) => {
            commit('SET_USER_INFO', res.data.data)
          })
          getOrderList().then(res => {
            commit('GET_ORDER_INFO', res.data.data.total)
          }).catch(() => {
            this.$store.commit('GET_ORDER_INFO', 0)
          })
          getOrderList(0).then(res => {
            commit('GET_ORDER_INFO1', res.data.data.total)
          }).catch(() => {
            this.$store.commit('GET_ORDER_INFO1', 0)
          })
          getOrderList(3).then(res => {
            commit('GET_ORDER_INFO2', res.data.data.total)
          }).catch(() => {
            this.$store.commit('GET_ORDER_INFO2', 0)
          })
        }
      } else {
        console.log(res)
      }
    })
  },
  pwd_set_token ({ commit }, data) {
    userLogin(data).then(res => {
      if (res.status === 401) {
        commit('SET_TEXT_FLAG', true)
        commit('SET_PWD_FLAG', true)
        let timer = null
        clearTimeout(timer)
        timer = setTimeout(() => {
          commit('SET_PWD_FLAG', false)
        }, 2000)

        commit('SET_PASSWORD', '')
      } else if (res.status === 200) {
        Message.success('登录成功')
        commit('SET_TOKEN', res.data.access_token)
        commit('SET_TOKEN1', res.data.refresh_token)
        commit('SET_USER_INFO', res.data)
        commit('SET_PHONE', data.username)
        commit('SET_PWD_FLAG', false)
        commit('SET_USER_ID', res.data.user_id)
        router.replace('my')
        if (data.flag) {
          commit('SET_PASSWORD', data)
        } else {
          commit('SET_PASSWORD', '')
        }
      }
    })
  }
}
export default actions
