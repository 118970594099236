import request from '@/utils/request'
import store from '@/store'
// 获取订单列表
export function getOrderList (status) {
  return request({
    url: 'pay/trade/orderListPage',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${store.state.token}`,
      'CLIENT-TOC': 'Y',
      'LOGIN-TYPE': 'PWD'
    },
    params: {
      size: 99,
      status
    }
  })
}
// 获取订单详情
export function getOrderInfo (id) {
  return request({
    url: 'pay/trade/orderDetails',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${store.state.token}`,
      'CLIENT-TOC': 'Y',
      'LOGIN-TYPE': 'PWD'
    },
    params: {
      orderId: id
    }
  })
}
// 未登录时获取资讯订单详情
export function getOrderArtInfo (id) {
  return request({
    url: 'pay/trade/articleOrderDetails',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${store.state.token}`,
      'CLIENT-TOC': 'Y',
      'LOGIN-TYPE': 'PWD'
    },
    params: {
      orderId: id
    }
  })
}
// 添加订单
export function addOrder (data) {
  console.log(data)
  return request({
    url: 'pay/goods/createOrder',
    method: 'post',
    headers: {
      Authorization: `Bearer ${store.state.token}`,
      'CLIENT-TOC': 'Y',
      'LOGIN-TYPE': 'PWD'
    },
    data: data
  })
}
// 添加资讯订单
export function addArticleOrder (data) {
  console.log(data)
  return request({
    url: 'pay/goods/createArticleOrder',
    method: 'post',
    headers: {
      Authorization: `Bearer ${store.state.token}`,
      'CLIENT-TOC': 'Y',
      'LOGIN-TYPE': 'PWD'
    },
    data: data
  })
}
// 删除订单
export function delOrder (data) {
  return request({
    url: 'pay/trade/delOrder',
    method: 'post',
    headers: {
      Authorization: `Bearer ${store.state.token}`,
      'CLIENT-TOC': 'Y',
      'LOGIN-TYPE': 'PWD'
    },
    data: data
  })
}
// // 购买商品
// export function buyShop (data) {
//   console.log(data)
//   return request({
//     url: 'pay/goods/zfbpay',
//     method: 'get',
//     headers: {
//       'Content-Type': 'application/x-www-form-urlencoded',
//       Authorization: `Bearer ${store.state.token}`,
//       'CLIENT-TOC': 'Y'
//     },
//     params: {
//       orderId: data
//     }
//   })
// }
// 购买商品
export function buyShop (data) {
  console.log(data)
  return request({
    url: 'pay/goods/pcbuy',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${store.state.token}`,
      'CLIENT-TOC': 'Y',
      'LOGIN-TYPE': 'PWD'
    },
    params: data
  })
}
export function buyWxShop (data) {
  return request({
    url: 'pay/goods/pcwxbuy',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${store.state.token}`,
      'CLIENT-TOC': 'Y',
      'LOGIN-TYPE': 'PWD'
    },
    params: data
  })
}
export function buyShop1 (data) {
  return request({
    url: 'pay/goods/buy',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${store.state.token}`,
      'CLIENT-TOC': 'Y',
      'LOGIN-TYPE': 'PWD'
    },
    params: data
  })
}
// 退换货
export function shopRefund (data) {
  console.log(data)
  return request({
    url: 'pay/refund/refundOrder',
    method: 'post',
    headers: {
      // 'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${store.state.token}`,
      'CLIENT-TOC': 'Y',
      'LOGIN-TYPE': 'PWD'
    },
    data: data
  })
}
// 查询物流
export function getLogistics (data) {
  return request({
    url: 'pay/trade/getLogistics',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${store.state.token}`,
      'CLIENT-TOC': 'Y',
      'LOGIN-TYPE': 'PWD'
    },
    params: {
      logisticsCode: data
    }
  })
}
// 购买记录
export function getPurchaseRecord (data) {
  return request({
    url: 'pay/goods/purchaseRecord',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${store.state.token}`,
      'CLIENT-TOC': 'Y',
      'LOGIN-TYPE': 'PWD'
    },
    params: {
      id: data
    }
  })
}
// 查询资讯
export function information (data) {
  console.log(data)
  return request({
    url: 'pay/trade/information',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${store.state.token}`,
      'CLIENT-TOC': 'Y',
      'LOGIN-TYPE': 'PWD'
    },
    params: data
  })
}
