import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/layout/index'
  },
  {
    path: '*',
    name: '404',
    component: () => import('../views/index/Index.vue')
  },
  {
    path: '/layout',
    name: 'layout',
    component: () => import('../layout/Layout.vue'),
    redirect: '/layout/index',
    children: [
      {
        path: 'index',
        name: 'index',
        component: () => import('../views/index/Index.vue'),
        meta: {
          perTitle: '首页',
          path: 'index'
          // keepAlive: true
        }
      },
      {
        path: 'productList',
        name: 'productList',
        component: () => import('../views/productList/ProductList.vue'),
        meta: {
          perTitle: '商品列表',
          path: 'productList',
          keepAlive: true
        }
      },
      {
        path: 'productListDetail',
        name: 'productListDetail',
        component: () => import('../views/productList/detail/Detail.vue'),
        meta: {
          perTitle: '商品列表',
          path: 'productList',
          title: '商品详情'
        }
      },
      {
        path: 'shopStreet',
        name: 'shopStreet',
        component: () => import('../views/shopStreet/ShopStreet.vue'),
        meta: {
          perTitle: '店铺街',
          path: 'shopStreet'
        }
      },
      {
        path: 'shopStreetDetail',
        name: 'shopStreetDetail',
        component: () => import('../views/shopStreet/detail/Detail.vue'),
        meta: {
          perTitle: '店铺街',
          path: 'shopStreet',
          title: '店铺详情'
        }
      },
      {
        path: 'information',
        name: 'information',
        component: () => import('../views/information/Information.vue'),
        meta: {
          perTitle: '行业资讯',
          path: 'information'
          // keepAlive: true

        }
      },
      {
        path: 'informationDetail',
        name: 'informationDetail',
        component: () => import('../views/information/detail/Detail.vue'),
        meta: {
          perTitle: '行业资讯',
          path: 'information',
          title: '资讯详情'
        }
      },
      {
        path: 'settledPlatform',
        name: 'settledPlatform',
        component: () => import('../views/settledPlatform/SettledPlatform.vue'),
        meta: {
          perTitle: '商家入驻',
          path: 'settledPlatform'
          // keepAlive: true

        }
      },
      {
        path: 'about',
        name: 'about',
        component: () => import('../views/about/About.vue'),
        meta: {
          perTitle: '关于我们',
          path: 'about',
          keepAlive: true

        }
      },
      {
        path: 'login',
        name: 'login',
        component: () => import('../views/login/Login.vue'),
        meta: {
          perTitle: '登录',
          path: 'login'
        }
      },
      {
        path: 'my',
        name: 'my',
        component: () => import('../views/my/My.vue'),
        meta: {
          perTitle: '个人中心',
          path: 'my'
        }
      },
      {
        path: 'orderInfo',
        name: 'orderInfo',
        component: () => import('../views/my/orderInfo/OrderInfo.vue'),
        meta: {
          perTitle: '个人中心',
          path: 'my',
          title: '订单详情'
        }
      },
      {
        path: 'cart',
        name: 'cart',
        component: () => import('../views/cart/Cart.vue'),
        meta: {
          perTitle: '购物车',
          path: 'cart'
        }
      },
      {
        path: 'payment',
        name: 'payment',
        component: () => import('../views/cart/payment/Payment.vue'),
        meta: {
          perTitle: '购物车',
          path: 'cart',
          title: '核对订单信息'
        }
      },
      {
        path: 'invoice',
        name: 'invoice',
        component: () => import('../views/cart/invoice/Invoice.vue'),
        meta: {
          perTitle: '购物车',
          path: 'cart',
          title: '发票',
          keepAlive: true

        }
      },
      {
        path: 'account',
        name: 'account',
        component: () => import('../views/my/account/Account.vue'),
        meta: {
          perTitle: '个人中心',
          path: 'my',
          title: '账号管理'

        }
      }
    ]
  }
]

const router = new VueRouter({
  routes,
  mode: 'hash'
})

export default router
